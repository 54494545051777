var ui_dropdown = {
  
  init: function(){
    
    var toggle = $('.dropdowns span');

    toggle.click(function(){

      var _this = $(this);
      var ul = $(this).next('ul');

      if(_this.hasClass('active')) {

        _this.removeClass('active');
        ul.removeClass('active').slideUp();

      } else {

        _this.addClass('active');
        ul.addClass('active').slideDown();

      }

    });
	},

};


$(document).ready(function() {
	ui_dropdown.init();
});

