/* JavaScript File                                                  */
/* lazyload-image.js                                                */
/* Modified November 26, 2018                                       */


var lazyloader = (function($, document) {

	var evt = [

		// lazy loader - function that runs before an image is unveiled 

		function($, document) {

			window.lazySizesConfig = window.lazySizesConfig || {};
			
			window.lazySizesConfig.lazyClass = 'lazyload';
			window.lazySizesConfig.loadedClass = 'lazyloaded';
			window.lazySizesConfig.loadingClass = 'lazyloading';
			window.lazySizesConfig.autosizesClass = 'autosizes';
			
			document.addEventListener('lazyloaded', function(e) {

				var $target = $(e.target);

				if ($target.hasClass('cover')) { $target.parent().addClass('cover-loaded'); }
			});
		}

	],
	initAll = function() {

		initEvt($, document);
	},
	initEvt = function($, document) {

		evt.forEach(function(e) {
			
			e($, document);
		});
	};
	
	return { init: initAll };

})(jQuery, document, window);

lazyloader.init();

