var ui_close = {
  
  init: function(){
    
    var toggle = $('[data-toggle=close]');

    toggle.each(function(){
      
      $(this).click(function(){

        var panel = $(this).attr('aria-controls');

        $('#'+panel).removeClass('active');

        $('html').removeClass('overflow-panel');
      });
    });
	},

};


$(document).ready(function() {
	ui_close.init();
});

$(window).on('load', function() {
	ui_close.init();
});
