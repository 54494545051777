var ui_menu = {
  
  init: function(){
    
    var toggle = $('[data-toggle=menu]');

    toggle.each(function(){
      
      $(this).click(function(){

        var panel = $(this).attr('aria-controls');

        $('#'+panel).addClass('active easing');

        $('html').addClass('overflow-panel');
      });
    });
	},

};


$(document).ready(function() {
	ui_menu.init();
});

$(window).on('load', function() {
	ui_menu.init();
});
